import { LoadingController, NavController, ToastController, MenuController } from '@ionic/angular';
import { Injectable, NgZone, EventEmitter, HostListener } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { IonSelect } from '@ionic/angular';
import * as moment from "moment"
import { DomSanitizer } from '@angular/platform-browser';

import { Subject, Subscription, interval, ReplaySubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { FormControl } from '@angular/forms';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  alert_window: any;

  public DEBUG = 'debug';
  public LOG = 'log';
  public ERROR = 'error';
  public primaryColor = '#5D445F';
  public secondaryColor = '#95CCB7';

  production: boolean = false;

  view: any[];

  public alertPresented: any;
  testCase: boolean = false;
  alert: any;
  isLoading = false;
  public showIonApp: boolean = false;
  public overlapAlertMessage: boolean = true;
  isLoggedIn: boolean = true;
  redirectURL: any = null;
  showMenu: boolean
  tempEmail: any = ''
  domainName: string = "";
  domainLogo: string = "";
  company: any = {};
  domain: any = {};
  showSideMenu: any = true;
  menuItems: any = [];
  headerMenuItems: any = [];
  savedFolderId: any = null;
  versionDate: any;
  documentId: string = ''
  redirectedCompleted: boolean = false
  phoneNumber: any
  pdfModal: any;
  pdfFile: any;

  reSendEmail: any;



  dropdownItems: any = [];
  openFromPopover: boolean = false

  public showIconOnly: boolean = false;

  domainLocales: any = [];
  locale: any;
  localeSelect: IonSelect;

  faqs: any
  status: number = 0
  logo: any
  customerLogo: any

  secretKey: string = null
  redirectDomainId: any = null

  notificationSearchText = '';
  searchText = '';
  currentPage = 0;
  orderBy = '';
  orderDir = '';
  errorStatus = '';


  windowHeight = window.innerHeight - 56 - 56;
  windowWidth: any = null;

  isRegister: boolean = false;
  showSendAgainbutton: boolean = false


  displayRadio: boolean = false;

  showAlert: boolean = false;
  alertMessageHeader: string = "";
  alertMessageBody: string = "";
  alertRedirect: boolean = false;

  scheduleIntervals: any = [];

  domains: any = []
  selectedDomain: any
  showHeaderMenu: boolean = true

  Companies: any = [];
  ObjectGroups: any = [];
  Objects: any = [];
  templateFoldersTree: any = [];
  ObjectTypes: any = [];
  folderEntryStatuses: any = [];

  helpImageList: any = [];
  helpImage: any = "";
  headerImage: any = ""


  isAdmin: boolean = false;
  isCustomer: boolean = false;
  isEmployee: boolean = false;
  isDriver: boolean = false;
  isInvoicer: boolean = false;
  userRoles: any[] = [];

  

  showDashboardTiles: boolean = true

  isFromDomainDetail: boolean = false
  userDomainId: any = ''
  companyDomainId: any = ''

  pageLimitOptions = [
    { value: 20 },
    { value: 50 },
    { value: 100 },
    { value: 250 },
  ];

  librarypageLimitOptions = [
    { value: 10 },
    { value: 50 },
    { value: 100 },
    { value: 250 },
  ];

  //Refresh list page after deleting or adding at detail page.
  refreshListPage: boolean = false;

  // alert_window: any;
  savedObjectFolderId: any = null;

  // search event
  searchTextValue: Subject<any> = new Subject();

  //open rightNave event
  toggleRightNav: Subject<any> = new Subject();


  // toggle event
  public onToggle: EventEmitter<any> = new EventEmitter<any>();
  public onResize: EventEmitter<any> = new EventEmitter<any>();
  public onDomainChange: EventEmitter<any> = new EventEmitter<any>();

  public performFaqAction: EventEmitter<any> = new EventEmitter<any>(null);

  // flag for form disable in file.page.ts (set from the folder entry page)
  filedisable: boolean = false;
  planningDateDisable: boolean = false;
  folderEntryDisable: boolean = false;
  disableDelete: boolean = false;
  disableUpdate: boolean = false;
  fileDisableDelete: boolean = false;
  fileDisableUpdate: boolean = false;
  toast: any;


  showQrScanner: boolean = true
  tempAuthKey: string = null;
  tempDefaultPassword: boolean = false;
  translationData: any = {};
  validFileExtensions = ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'txt', 'docx', 'xls', 'xlsx', 'csv', 'zip', 'gif', 'img', 'bmp', 'dwg', 'eml', 'html', 'ppt', 'pptx', 'rtf', 'xml', 'PNG', 'JPG', 'JPEG', 'PDF', 'DOC', 'TXT', 'DOCX', 'XLS', 'XLSX', 'CSV', 'ZIP', 'GIF', 'IMG', 'BMP', 'DWG', 'EML', 'HTML', 'PPT', 'PPTX', 'RTF', 'XML'];

  customActivityListSelectOptions: any = {
    cssClass: 'custom-select-interface'
  }

  customActivityListUrgencySelectOptions: any = {
    cssClass: 'custom-urgency-select-interface'
  }

  customLocaleSelect = {
    cssClass: 'customLocaleSelect'
  }
  IsHideHelpChatTile: string = 'true';
  notificationCountSubscription: Subscription;

  maintenanceObjectexpandedNodes: any = [];
  objectExpandedNodes: any = [];
  objectTypeExpandedNodes: any = [];
  monitorTreeExpandedNodes: any = [];
  faqList: any = []

  tempUserName: any = null;
  tempDomainName: any = null;

  showOnlyIcon: boolean = true;

  interval: any;

  phonePattern: any = /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;
  postalCodePattern = /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/;
  otpCodePattern = /^[0-9]{6}/;
  bankAccountpattern = /^\w{18}$/;
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  URLPattern = /(http|https):\/\/[a-z0-9\-_]+(\.[a-z0-9\-_]+)+([a-z0-9\-\.,@\?^=%&;:\/~\+#]*[a-z0-9\-@\?^=%&;\/~\+#])?/;
  guidPattern = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;


  updateNotification: Observable<any>;
  private alertBroadcast = new Subject<any>();
  public closeRightDrawer: Subject<string>;
  // @HostListener('document:keydown.enter', ['$event'])
  // onKeydownHandler(event: KeyboardEvent) {
  //   if (this.alert_window) {
  //     const okButton: any = document.querySelectorAll('ion-alert button')[1];
  //     okButton.click();
  //     this.alert_window.dismiss();
  //     this.alert_window = null;
  // }
  // }

  constructor(public loadingController: LoadingController, public toastController: ToastController, public alertController: AlertController, public router: Router, private _ngZone: NgZone, public ngxSmartModalService: NgxSmartModalService, public navCtrl: NavController, private _apiService: ApiService, private sanitizer: DomSanitizer, public menuCtrl: MenuController) {
    this.alertPresented = false;
    this.updateNotification = this.alertBroadcast.asObservable();
    this.closeRightDrawer = new Subject<any>();

    if (localStorage.locale && localStorage.locale == '1043') {
      this.translationData = {
        "label-ok": "Ok",
      };
    } else {
      this.translationData = {
        "label-ok": "Ok",
      };
    }
    if (localStorage.locale && localStorage.locale != "null") {
      this.locale = Number(localStorage.locale);
    }
    if (localStorage.companyLogo) {
      this.company.CompanyLogo = localStorage.companyLogo;
    }

    if (localStorage.Logo) {
      this.logo = localStorage.Logo;
    }

    if (localStorage.domainName) {
      this.domainName = localStorage.domainName;
    }
    if (!localStorage.authenticationKey) {
      this.isLoggedIn = false;
    }
    if (localStorage.domainId) this.domain.DomainId = localStorage.domainId;
    if (localStorage.domainId) this.domain.Name = localStorage.domainName;
    /*
      Push Dropdownlist type to dropdownItems and Menu type to menuItems by checking AccessLabelType
    */
    if (localStorage.menuItems) {
      let items = JSON.parse(localStorage.menuItems);
      this.menuItems = [];
      this.dropdownItems = [];
      for (let index = 0; index < items.length; index++) {
        if (items[index].AccessLabelType === 'Dropdownlist') {
          this.dropdownItems.push(items[index]);
        }
        if (items[index].AccessLabelType === 'Menu' && items[index].Menu != '') {
          if (items[index].Icon.startsWith("fas fa-") || items[index].Icon.startsWith("far fa-")) items[index].type = "fortawesome"
          else items[index].type = "ionicons"
          this.menuItems.push(items[index]);


        }
        if (items[index].AccessLabelType === 'Menu' && items[index].Menu == '') {
          items[index].Menu = items[index].MenuName;
          if (items[index].Icon.startsWith("fas fa-") || items[index].Icon.startsWith("far fa-")) items[index].type = "fortawesome"
          else items[index].type = "ionicons"
          this.menuItems.push(items[index]);
        }

        if (items[index].AccessLabelType === 'HeaderMenu') {
          this.headerMenuItems.push(items[index]);
        }
      }

    }
    this.menuItems = [...this.menuItems]
    this.dropdownItems = [... this.dropdownItems]
    if (localStorage.domainLocales && localStorage.domainLocales != "undefined") {
      this.domainLocales = JSON.parse(localStorage.domainLocales);
    }
    this.testCase = true;

  }

  /*
    => Check localStorage for domainLocales if not found get the domain locales and update the localStorage and commonservice's domainLocales0000
    => Append the span element to select button with class name from LocaleIconUrl
  */
  async openLocaleModel() {
    try {
      if (localStorage.domainLocales) {
        this.domainLocales = JSON.parse(localStorage.domainLocales);
      } else {
        this.presentLoading();
        let localeData = await this._apiService.getDomainLocales('', 1, 0);
        this.domainLocales = localeData.body;
        localStorage.domainLocales = JSON.stringify(localeData.body);
        this.dismiss();
      }
      this.ngxSmartModalService.getModal('selectLocale').backdrop = false;
      this.ngxSmartModalService.getModal('selectLocale').open();
      this.ngxSmartModalService.getModal('selectLocale').close();
      setTimeout(async () => {
        await this.localeSelect.open();
        let alertButtons = document.querySelectorAll('.customLocaleSelect button.alert-radio-button');
        alertButtons.forEach((button, index) => {
          button.querySelector('.alert-button-inner').insertAdjacentHTML('beforeend', "<span class='flag-icon '></span>");
          button.querySelector('.flag-icon').className += this.domainLocales[index].LocaleIconUrl
        })
      }, 100)
    } catch (error) {
      this.dismiss();
      this.log(error, this.ERROR);
    }
  }

  // called when locale select is clicked (on every page)
  // add span element with respective flag icon class name
  onLocaleSelectClicked() {
    setTimeout(() => {
      let alertButtons = document.querySelectorAll('.customLocaleSelect button.alert-radio-button');
      alertButtons.forEach((button, index) => {
        button.querySelector('.alert-button-inner').insertAdjacentHTML('beforeend', "<span class='flag-icon '></span>");
        button.querySelector('.flag-icon').className += this.domainLocales[index].LocaleIconUrl
      })
    }, 200)
  }

  presentLoading() {
    this.isLoading = true;
  }

  dismiss() {
    this.isLoading = false;
  }

  async presentMessageConfirmation(header, errMessage) {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: header,
        message: errMessage,
        buttons: [
          {
            text: this.getLocalTranslation('label-yes'),
            handler: async () => {
              resolve("Yes");
            }
          },
          {
            text: this.getLocalTranslation('label-no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              reject("Cancel");
            }
          }]
      });
      // this.alert_window = alert;
      await alert.present();
    })
  }

  async presentAlert(header, errMessage) {
    this.alertMessageHeader = header;
    this.alertMessageBody = errMessage;
    this.showAlert = true;
  }



  // If alertRedirect == true, navigate to dashboard on alert dismiss
  async presentRoutingAlert(header, errMessage, errStatus) {
    this.alertMessageHeader = header;
    this.alertMessageBody = errMessage;
    this.showAlert = true;
    this.alertRedirect = true;
    this.errorStatus = errStatus;
    console.log("alert message :", errMessage);
  }

  dismissAlert() {
    this.showAlert = false;
    this.showSendAgainbutton = false;
    this.overlapAlertMessage = true;
    if (this.alertRedirect) {
      // route to dashboard
      this.alertRedirect = false;
      if (this.errorStatus == '402' && (this.router.url == "/documentgenerator" || this.router.url.includes('/documentgenerator/wizard/'))) {
        this.errorStatus = '';
        if (localStorage.roleCode.split(",").includes('UserRole_CustomerAdmin')) {

          this.router.navigate(["maintenance/domains/" + localStorage.domainId]);
        } else {
          if (this.router.url.includes('/documentgenerator/wizard/')) {
            this.router.navigate(['dashboard'], { replaceUrl: true });
          }
        }
      } else {
        this.errorStatus = '';
        this.router.navigate(['dashboard'], { replaceUrl: true });
      }
    }
  }

  goBack(route) {
    this.router.navigate(['/' + route]);
  }

  goReservations() {
    this.router.navigate(['reservations'], { replaceUrl: true })
  }

  goMaintenance() {
    this.router.navigate(['maintenance'], { replaceUrl: true })
  }

  goMainSettingsMenuItem() {
    this.router.navigate(['mainsettings'], { replaceUrl: true })
  }

  goDataMenuItem() {
    this.router.navigate(['data'], { replaceUrl: true })
  }

  goSafteyMenuItem() {
    this.router.navigate(['safety'], { replaceUrl: true })
  }

  goInvoiceMenuItem() {
    this.router.navigate(['invoicing'], { replaceUrl: true })
  }

  // console log the message
  log(message, type) {
    if (type === this.ERROR) {
      console.error(message);
    }
    if (!this.production) {
      if (type === this.DEBUG) {
        console.debug(message);
      }
      else {
        console.log(message);
      }
    }
  }

  clearData() {
    let locale = localStorage.locale;
    let domainLocales = localStorage.domainLocales
    let Logo = localStorage.Logo ? localStorage.Logo : null;
    let userName = localStorage.userName ? localStorage.userName : null;
    this.company.CompanyLogo = null;
    let translationData = localStorage.translations;
    let loginPreference = localStorage.loginPreference ? localStorage.loginPreference : null;



    localStorage.clear();
    sessionStorage.clear();


    localStorage.loginPreference = loginPreference;

    localStorage.locale = locale;
    localStorage.domainLocales = domainLocales;
    localStorage.Logo = Logo;
    localStorage.userName = userName
    localStorage.translations = translationData;

    if (this.notificationCountSubscription) this.notificationCountSubscription.unsubscribe();
  }

  presentAlertConfirm() {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: this.getLocalTranslation('label-pleaseConfirm'),
        message: this.getLocalTranslation('label-deleteItems'),
        buttons: [
          {
            text: this.getLocalTranslation('label-yes'),
            handler: async () => {
              resolve("Yes");
            }
          },
          {
            text: this.getLocalTranslation('label-no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              reject("Cancel");
            }
          }]
      });
      await alert.present();
    })
  }

  presentAlertConfirmWithText(bodyText) {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: this.getLocalTranslation('label-pleaseConfirm'),
        message: this.getLocalTranslation(bodyText),
        buttons: [
          {
            text: this.getLocalTranslation('label-yes'),
            handler: async () => {
              resolve("Yes");
            }
          },
          {
            text: this.getLocalTranslation('label-no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              reject("Cancel");
            }
          }]
      });
      await alert.present();
    })
  }

  presentDocumentAlertConfirm() {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: this.getLocalTranslation('label-pleaseConfirm'),
        message: this.getLocalTranslation('label-deleteDocuments'),
        buttons: [
          {
            text: this.getLocalTranslation('label-yes'),
            handler: async () => {
              resolve("Yes");
            }
          },
          {
            text: this.getLocalTranslation('label-no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              reject("Cancel");
            }
          }]
      });
      await alert.present();
    })
  }

  // check for valid color
  isColor(strColor) {
    let isOk = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(strColor);
    return isOk;
  }

  // darkens the given color with the given percentage
  darkenColor(color, percent) {
    let num = parseInt(color.replace("#", ""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) - amt,
      B = (num >> 8 & 0x00FF) - amt,
      G = (num & 0x0000FF) - amt;
    return "#" + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
  };

  getTimeZone(date) {
    return moment(new Date(date), "DD-MM-YYYY HH:mm:ss").format('YYYY-MM-DDTHH:mm:ssZ');
  }

  // Get translation from localStorage - if not found return the label-text
  getLocalTranslation(labelText) {
    try {
      let text = JSON.parse(localStorage.translations)[labelText];
      return text ? text : labelText;
    } catch (error) {
      return labelText;
    }
  }

  async showToast(message) {
    const toast = await this.toastController.create({
      message: message,
      animated: true,
      cssClass: "toast",
      duration: 2000,
      position: "top"
    });
    toast.present();
  }


  // Remove the parent property from node
  removeCircularStructure(root) {
    let children = root.children;
    if (root.parent) delete root.parent;
    for (let i = 0; i < children.length; i++) {
      let child = children[i];
      if (child.parent) delete child.parent;
      if (child.children && child.children.length > 0) {
        this.removeCircularStructure(child);
      }
    }
  }

  sortDesc(a, b) {
    return b < a ? -1 : b > a ? 1 : b >= a ? 0 : NaN;
  }

  sortAsc(a, b) {
    return a < b ? -1 : a > b ? 1 : a >= b ? 0 : NaN;
  }

  // get sanitized html for forms
  getSanitizedHtml(control) {
    return this.sanitizer.bypassSecurityTrustHtml(control.value);
  }

  async getDomains() {
    try {
      if (localStorage.domains && localStorage.domains != 'null' && JSON.parse(localStorage.domains).length > 0) {
        this.domains = JSON.parse(localStorage.domains);

        this.domains.forEach(element => {
          if (element.DomainId == this.domain.DomainId) {
            element.isSelected = true
          } else {
            element.isSelected = false
          }
        });

        this.domains.sort(function (a, b) {
          return (a.isSelected === b.isSelected) ? 0 : a.isSelected ? -1 : 1;

        });
        console.log(this.domains)


      } else {
        let domainsData = await this._apiService.getDomains('', '', '', 1, 0, false);
        this.domains = domainsData.body;
        this.domains.forEach(element => {
          if (element.DomainId == this.domain.DomainId) {
            element.isSelected = true
          } else {
            element.isSelected = false
          }
        });
        localStorage.domains = JSON.stringify(domainsData.body);
      }
    } catch (error) {
      this.log(error, this.ERROR);
    }
  }

  // async getCompanies() {
  //   try {
  //     let companyData = await this._apiService.getCompanies('', '', '', 1, 0, 1, '', '');
  //     this.Companies = companyData.body;
  //     localStorage.companies = JSON.stringify(this.Companies);
  //   } catch (error) {
  //     this.log(error, this.ERROR);
  //   }
  // }

  // async getObjectGroups() {
  //   try {
  //     let objectGroupData = await this._apiService.getObjectGroups('','', '', 1, 0);
  //     this.ObjectGroups = objectGroupData.body;
  //     localStorage.objectGroups = JSON.stringify(this.ObjectGroups);
  //   } catch (error) {
  //     this.log(error, this.ERROR);
  //   }
  // }

  // async getObjects() {
  //   try {
  //     let objectData = await this._apiService.getObjects('', '', '', '', '', '', 1, 0, 'active');
  //     this.Objects = objectData.body;
  //     localStorage.objects = JSON.stringify(this.Objects);
  //   } catch (error) {
  //     this.log(error, this.ERROR);
  //   }
  // }

  // async getTempalteFoldersTree(){
  //   try {
  //     let foldersData = await this._apiService.getTemplateFoldersTree('', '');
  //     this.templateFoldersTree = foldersData.body;
  //     localStorage.templateFoldersTree = JSON.stringify(this.templateFoldersTree);
  //   } catch (error) {
  //     this.log(error, this.ERROR);
  //   }
  // }

  // async getFolderEntryStatuses(){
  //   try {
  //     let statusData = await this._apiService.getFolderEntryStatuses();
  //     this.folderEntryStatuses = statusData.body;
  //     localStorage.setItem('folderEntryStatuses', JSON.stringify(statusData.body));
  //   } catch (error) {
  //     this.log(error, this.ERROR);
  //   }
  // }

  // async getObjectTypes() {
  //   try {
  //     let objectTypesData = await this._apiService.getObjectTypes('', '', '', 1, 0);
  //     this.ObjectTypes = objectTypesData.body;
  //     localStorage.objectTypes = JSON.stringify(this.ObjectTypes)
  //   } catch (error) {
  //     this.log(error, this.ERROR);
  //   }
  // }

  /*
    Push Dropdownlist type to dropdownItems and Menu type to menuItems by checking AccessLabelType
  */
  formatMenuItems() {

    let items = [];
    if (localStorage.menuItems) items = JSON.parse(localStorage.menuItems);
    this.menuItems = [];
    this.dropdownItems = [];
    this.headerMenuItems = [];
    items.forEach(element => {
      if (element.AccessLabelType === 'Menu' && element.Menu != '') {
        if (element.Icon.startsWith("fas fa-") || element.Icon.startsWith("far fa-")) element.type = "fortawesome"
        else element.type = "ionicons"
        this.menuItems.push(element);
      }
      if (element.AccessLabelType === 'Menu' && element.Menu == '') {
        if (element.Icon.startsWith("fas fa-") || element.Icon.startsWith("far fa-")) element.type = "fortawesome"
        else element.type = "ionicons"
        element.Menu = element.MenuName;
        this.menuItems.push(element);
      }
      if (element.AccessLabelType === 'Dropdownlist') {
        this.dropdownItems.push(element);
      }

      if (element.AccessLabelType === 'HeaderMenu') {
        this.headerMenuItems.push(element);
      }

      if (element.childMenuItems && element.childMenuItems.length > 0) {
        element.childMenuItems.forEach(element => {
          if (element.Icon.startsWith("fas fa-") || element.Icon.startsWith("far fa-")) element.type = "fortawesome"
          else element.type = "ionicons"
        });
      }

    });
    if (this.headerMenuItems.length == 0) {
      this.showHeaderMenu = false
    } else {
      this.headerMenuItems.forEach(element => {
        if (element.childMenuItems.length == 0) {
          this.showHeaderMenu = false
        } else {
          this.showHeaderMenu = true
        }
      });
    }
  }

  // Scroll to error element (with class name: 'ng-invalid')
  scrollToError(): void {
    let firstElementWithError = document.querySelector('ion-input.ng-invalid');
    if (!firstElementWithError) firstElementWithError = document.querySelector('ion-textarea.ng-invalid');
    if (!firstElementWithError) firstElementWithError = document.querySelector('textarea.ng-invalid');
    if (!firstElementWithError) firstElementWithError = document.querySelector('ng-select2.ng-invalid');
    this.scrollTo(firstElementWithError);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }




  verifyAuthKey() {
    return this._apiService.verifyAuthkey();
  }

  // white space validation for forms
  public noWhitespace(control: FormControl) {
    let isWhitespace = String((control.value || '')).trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true }
  }



  changeRadioStyle() {
    // this.presentLoading();
    setTimeout(() => {

      let radio = document.querySelectorAll('ion-radio');
      if (radio) {

        for (var i = 0; i < radio.length; i++) {
          let radioItem = radio[i];
          if (radioItem.shadowRoot.querySelector('.radio-icon')) {
            radioItem.shadowRoot.querySelector('.radio-icon').setAttribute('style', 'border: none;box-shadow: 0px 3px 0 0 #dfdfdf;background-color: #ffffff;curson:pointer');
          }
          if (radioItem.className.includes('radio-checked')) {
            radioItem.shadowRoot.querySelector('.radio-inner').setAttribute('style', 'transform: scale3d(0.8, 0.8, 0.8);');
          } else if (radioItem.shadowRoot.querySelector('.radio-inner')) {
            radioItem.shadowRoot.querySelector('.radio-inner').setAttribute('style', 'transform: scale3d(0, 0, 0);');
          }

        }

      }
      this.displayRadio = true;
      // this.dismiss();
    }, 100);
  }

  findElement(arr, node, key, keyValue) {
    var matches = [];
    if (!Array.isArray(arr)) return matches;

    arr.forEach(i => {
      if (keyValue.split('/').length > 3 && keyValue.indexOf('maintenance/domains') == -1) {
        keyValue = keyValue.split('/')[0] + '/' + keyValue.split('/')[1] + '/' + keyValue.split('/')[2];
      }

      if (keyValue.indexOf('reservations') == 1 && keyValue.split('/').length == 3) {
        keyValue = keyValue.split('/')[0] + '/' + keyValue.split('/')[1];
      }

      // if ((keyValue.indexOf('data') == 1 || keyValue.indexOf('mainsettings') == 1) && keyValue.split('/').length == 3){
      //   keyValue = keyValue.split('/')[0] + '/' + keyValue.split('/')[1] + '/' + keyValue.split('/')[2];
      // }

      if (i[key].toLowerCase() == keyValue) {
        matches.push(i);
      } else {
        let childResults = this.findElement(i[node], node, key, keyValue);
        if (childResults.length)
          matches = [...matches, ...childResults];
      }
    })

    return matches;
  }

  findAll(arr, term) {
    var matches = [];
    if (!Array.isArray(arr)) return matches;

    arr.forEach(i => {
      if (i.type.toLowerCase() == term) {
        matches.push(i);
        let childResults = this.findAll(i.children, term);
        if (childResults.length)
          matches = [...matches, ...childResults];
      } else {
        let childResults = this.findAll(i.children, term);
        if (childResults.length)
          matches = [...matches, ...childResults];
      }
    })

    return matches;
  }

  async sendLinkAgain() {
    this.showSendAgainbutton = false
    this.showAlert = false
    try {
      this.presentLoading()
      let obj = {
        EmailAddress: this.reSendEmail,
      }
      let res = await this._apiService.registration(obj, true);
      this.dismiss();
      this.reSendEmail = ''
      this.showToast(this.getLocalTranslation('label-successfullyEmailSend'));
      this.log(obj, this.LOG);
    } catch (error) {
      this.dismiss();
      this.log(error, this.ERROR)
    }
  }


  async getLocales() {
    try {
      let allLocales = [];
      if (localStorage.domainLocales) {
        allLocales = JSON.parse(localStorage.domainLocales);
        for (let i = 0; i < allLocales.length; i++) {

          if (allLocales[i].LocaleIconUrl == 'flag-icon-nl') {
            allLocales[i].Icon = 'assets/flags/1x1/nl.svg';
            allLocales[i].LocaleTranslationCode = 'label-netherlands';
            allLocales[i].TranslatedText = this.getLocalTranslation('label-netherlands');

          }
          if (allLocales[i].LocaleIconUrl == 'flag-icon-gb') {
            allLocales[i].Icon = 'assets/flags/1x1/gb.svg';
            allLocales[i].LocaleTranslationCode = 'label-english';
            allLocales[i].TranslatedText = this.getLocalTranslation('label-english');
          }
        }

      } else {
        let localeData = await this._apiService.getDomainLocales('', 1, 0);
        allLocales = localeData.body;
        localStorage.domainLocales = JSON.stringify(localeData.body);
        for (let i = 0; i < allLocales.length; i++) {
          if (allLocales[i].LocaleIconUrl == 'flag-icon-nl') {
            allLocales[i].Icon = 'assets/flags/1x1/nl.svg';
            allLocales[i].LocaleTranslationCode = 'label-netherlands';
            allLocales[i].TranslatedText = this.getLocalTranslation('label-netherlands');
          }
          if (allLocales[i].LocaleIconUrl == 'flag-icon-gb') {
            allLocales[i].Icon = 'assets/flags/1x1/gb.svg';
            allLocales[i].LocaleTranslationCode = 'label-english';
            allLocales[i].TranslatedText = this.getLocalTranslation('label-english');

          }
        }
      }

      allLocales.sort((a, b) => {
        if (a.TranslatedText < b.TranslatedText) return -1;
        if (a.TranslatedText > b.TranslatedText) return 1;
        return 0;
      });

      return allLocales;
    } catch (error) {
      this.log(error, this.ERROR);
    }
  }
}
